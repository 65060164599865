<template>
    <div class="container">
        {{ message }}
    </div>
</template>

<script>
export default {
    name: "Error",
    data() {return {
        message: ''
    }},
    created() {
        const msg = this.$route.query.msg
        this.message = msg || '未知错误'
    }
}
</script>

<style scoped>

</style>
